import React, { useState, useEffect } from 'react';
// import '../App.css';
// import '../index.css'
import Main from '../pages/Containers/Main.js'
import Footer from '../pages/Components/Footer.js'
import { graphql } from 'gatsby';

const  Issue = ({ data, pageContext }) => {
  let currentIssue = pageContext.issue

  let currentArticles = data.postgres.articles.edges
  // only for build
  if(!!currentArticles[0].node){
    currentArticles.forEach((a,i) => {
      currentArticles[i] = a.node
    })
  }
  
  if (typeof(document) !== 'undefined'){
    document.documentElement.style.background = `#${currentIssue.lowlightColor}`
    document.documentElement.style.color = `#${currentIssue.highlightColor}`
  }
  
  const [ articles, setArticles ] = useState([...currentArticles])

    return (
      <>
        <Main
          articles={articles}
          topic={currentIssue.topic}
          slug={currentIssue.slug}
          highlightColor={currentIssue.highlightColor}
          lowlightColor={currentIssue.lowlightColor}
          />
          <Footer></Footer>
      </>
    );
}

export default Issue;
console.log("### ISSUE PAGE QUERY ###")
export const query = graphql`
query CurrentArticlesQuery ($currentIssueId : Int) {
  postgres {
    articles(condition: {issue: $currentIssueId}) {
      edges {
        node {
          id
          title
          slug
          pubDate
          heroImageUrl
          authorName
        }
      }
    }
  }
}
`
export function Head ({pageContext}){
  let currentIssue = pageContext.issue
  return (
    <>
       <meta charSet="utf-8" />
       {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
       <meta name="viewport" content="width=device-width, initial-scale=1" />
       <meta name="theme-color" content="#000000" />
       <meta name="description"
         content="Monthly magazine for long-form articles and podcasts on videogames"
       /> 
       <title>Bullet Points Monthly - {currentIssue.topic}</title>
    </>
  )
}